import React, { useEffect, useState } from "react"
import * as styles from "styles"
import LogoLarge from "../assets/svg/logo.svg"

import styled from "@emotion/styled"
import { Link, graphql, useStaticQuery } from "gatsby"

const FooterWrapper = styled.footer`
  position: fixed;
  width: 100vw;
  z-index: ${({zIndex}) => zIndex};
  top: 0;
  left: 0;
  pointer-events: all !important;
  opacity: ${({footerShown}) => footerShown ? 1:0};

  ${styles.GridMargin};
  display: flex; 
  flex-direction: column;
  justify-content: space-between;
  padding-top: 36px;
  padding-bottom: 36px;
  height: 100vh;
  background: ${styles.palette.black};

  ${styles.media.tablet`
    padding-top: 18px;
    padding-bottom: 18px;
    height: 100%;
  `}
  ${styles.media.mobile`
    padding-top: 15px;
    padding-bottom: 15px;
    height: 100%;
  `}

    @media (min-width: 1800px) {
      padding-left: 36px;
      padding-right: 36px;
    }
`

const Logo = styled(LogoLarge)`
  opacity: ${({footerShown}) => footerShown ? 1 : 0};
  transition-duration: 1s;
  transition-delay: 0.7s;
  width: fit-content;
  grid-column: 1/9;
  // margin-bottom: 180px;
  ${styles.media.tablet`
    // margin-bottom: 120px;
    width: calc(100vw - 36px);
  `}
  ${styles.media.mobile`
    // margin-bottom: 120px;
    width: calc(100vw - 30px);
  `}
`
const Contact = styled.div`
`
const CopyrightWrapper = styled.div`
  grid-column: 1/9;
  width: 100%;
  ${styles.GridNoMargin}; 
  height: 10px;
  padding-top: 20px;
  // margin-top: 110px;
  ${styles.media.tablet`
    // margin-top: 420px;
    height: 36px;
    padding-top: 18px;
    margin-top: -10vh;
  `}
  ${styles.media.mobile`
    // margin-top: 120px;
    height: 76px;
    padding-top: 25px;
    padding-bottom: 25px;
  `}
`
const Line = styled.div`
  border-top: solid 1px white;
  opacity: ${({footerShown}) => footerShown ? '1':'0'};
  // width: ${({footerShown}) => footerShown ? 'calc(100vw - 52px)' : '0px'};
  // left: ${({footerShown}) => footerShown ? '26px' : '50vw'};
  width: calc(100vw - 52px);
  left: 26px;
  height: 1px;
  margin-top: -25px;
  position: absolute;
  transition-delay: 1.5s;
  transition-duration: 1s;
  @media (max-width: ${styles.sizes.tablet}px) {
    // width: ${({footerShown}) => footerShown ? 'calc(100vw - 40px)' : '0px'};
    // left: ${({footerShown}) => footerShown ? '20px' : '50vw'};  
    width: calc(100vw - 40px);
    left: 20px;
  }
  @media (max-width: ${styles.sizes.mobile}px) {
    // width: ${({footerShown}) => footerShown ? 'calc(100vw - 30px)' : '0px'};
    // left: ${({footerShown}) => footerShown ? '15px' : '50vw'};
    width: calc(100vw - 30px);
    left: 15px;
  }
  `

const Rights = styled.div`
  ${styles.text};
  font-size: 12px;
  grid-column: 1/3;
  color: ${styles.palette.off_white};
  text-transform: uppercase;
  text-align: left;
  opacity: ${({footerShown}) => footerShown ? 1 : 0};
  transition-duration: 1s;
  transition-delay: 1.7s;
  @media (max-width: 1500px) {
    grid-column: 1/3;
  }

  @media (max-width: 1430px) {
    grid-column: 1/5;
    text-align: left;
  }
  ${styles.media.tablet`
    grid-column: 1/6;
    margin-left: 0px;
  `}

  ${styles.media.mobile`
    grid-column: 1/9;
  `}
`


const Copyright = styled.div`
  display: flex;
  grid-column: 3/5;
  justify-content: flex-start;

  a {
    font-size: 12px;
    text-transform: uppercase;
    opacity: ${({footerShown}) => footerShown ? 1 : 0};
    transition-duration: 1s;
    transition-delay: 1.7s;
    margin-right: 20px;

    // &:nth-child(1) {
    //   transition-delay: 2.4s;
    // }
    // &:nth-child(2) {
    //   transition-delay: 2.6s;
    // }
    // &:nth-child(3) {
    //   transition-delay: 2.8s;
    // }
    // &:nth-child(4) {
    //   transition-delay: 3s;
    // }
  }

  @media (max-width: 1900px) {
    grid-column: 5/7;
  }

  @media (max-width: 1500px) {
    grid-column: 5/7;
  }

  @media (max-width: 1400px) {
    grid-column: 5/9;
    padding-left: 0px;
  }

  ${styles.media.tablet`
    grid-column: 6/9;
    justify-content: space-between;
    a {
      margin-right: 0px;
    }
  `}

  ${styles.media.mobile`
    padding-left: 0px;
    grid-column: 1/9;
    flex-wrap: wrap;
    margin-top: -10px;
    a {
      width: calc(50% - 7.5px);
      margin: 5px 0;
    }
  `}
`

const Address = styled.address`
  ${styles.text};
  display: block;
  color: ${styles.palette.off_white};
  font-size: 20px;
  margin-bottom: 3px;
  white-space: pre;
  ${styles.media.tablet`
    font-size: 16px;
  `}
`

const LinksWrapper = styled.div`
  ${styles.GridNoMargin};
  width: 100%;
  grid-column: span 12;
  ${styles.media.tablet`
    margin-top: -10vh;
  `}
  ${styles.media.mobile`
    margin-top: -5vh;
  `}
`

const Group = styled.div`
  grid-column: span 2;
  opacity: ${({footerShown}) => footerShown ? 1 : 0};
  transition-duration: 1s;
  transition-delay: ${({delay}) => delay}s;

  .opacity-wrapper {
    opacity: ${({footerShown}) => footerShown ? 1 : 0};
    transition-duration: 1s;
    transition-delay: ${({delay}) => delay + 0.1}s;
  }

  ${styles.media.mobile`
    grid-column: span 4;
    margin-bottom: 10px;
  `}
`
const SubTitle = styled(styles.text_)`
  color: ${styles.palette.off_white};
  text-transform: uppercase;
  margin-bottom: 10px;
  font-size: 16px;
  ${styles.media.tablet`
    font-size: 13px;
  `}
`
const GLink = styled(Link)`
  ${styles.text};
  display: block;
  color: ${styles.palette.off_white};
  font-size: 20px;
  ${styles.media.tablet`
    font-size: 16px;
  `}
`

const ContactLink = styled.a`
  ${styles.text};
  display: block;
  color: ${styles.palette.off_white};
  font-size: 20px;
  ${styles.media.tablet`
    font-size: 16px;
  `}
`

const query = graphql`
  {
    contentfulContactUs {
      phone
      email {
        email
      }
      facebook 
      address {
        address
      }
      instagram 
      twitter
      linkedIn 
      careers
    }
  }
`

const Footer = ({ noMargin, siteMetadata, footerShown, footerZindex }) => {
  let { contentfulContactUs } = useStaticQuery(query)

  return (
    <FooterWrapper noMargin={noMargin} footerShown={footerShown} zIndex={footerZindex}>
          <Logo footerShown={footerShown}/>
          <LinksWrapper>
            <Group footerShown={footerShown} delay={0.7}>
              <SubTitle>{"Site Index"}</SubTitle>
              {siteMetadata.titles
              .filter(x => x.nav)
              .map(({ pathname, title }, key) => (
                <GLink to={pathname} key={key}>
                  {title}
                </GLink>
              ))}
            </Group>
            <Group footerShown={footerShown} delay={0.9}>
              <SubTitle>{"Connect"}</SubTitle>
              <div className="opacity-wrapper">
                <ContactLink href={contentfulContactUs.linkedIn} target="_blank">
                    LinkedIn
                </ContactLink>
                <ContactLink href={contentfulContactUs.instagram} target="_blank">
                    Instagram
                </ContactLink>
                <ContactLink href={contentfulContactUs.facebook} target="_blank">
                    Facebook
                </ContactLink>
                {/* <ContactLink href={contentfulContactUs.twitter} target="_blank">
                    Twitter
                </ContactLink> */}
                <ContactLink href={contentfulContactUs.careers} target="_blank">
                    Careers
                </ContactLink>
              </div>
            </Group>
            <Group footerShown={footerShown} delay={1.1}>
              <SubTitle>{"Get In Touch"}</SubTitle>
              <Address>{contentfulContactUs.address.address}</Address>
                <br/>
              <Contact>
                {contentfulContactUs.phone.map(phone => (
                  <ContactLink href={"tel:" + phone} target="_blank">{phone}</ContactLink>
                ))}
                <ContactLink href={"mailto:" + contentfulContactUs.email.email} target="_blank">
                  {contentfulContactUs.email.email}
                </ContactLink>
              </Contact>
            </Group>
          </LinksWrapper>
      <CopyrightWrapper footerShown={footerShown}>
        <Line footerShown={footerShown} />
        <Rights footerShown={footerShown}>
          © {new Date().getFullYear()} Thomas Young Group. All rights reserved{" "}
        </Rights>
        <Copyright footerShown={footerShown}>
          <GLink to="/terms-of-use">
            Terms of use
          </GLink>
          <GLink to="/privacy-policy">
            Privacy Policy
          </GLink>
        </Copyright>

      </CopyrightWrapper>
    </FooterWrapper>
  )
}

export default Footer
